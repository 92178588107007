export default {
    "props": {
        "data": {
          "accordion": [ 
        ],
        "status": [
        ]
      } 
    },
    "sx": (theme) => ({
        background: "red",
        "& .testclass": {
            borderCollapse: 'collapse',
            width: '100%',
            background: "red"
        },
        "& .accordion-title": {
          background: "red"

        }
        
    })
}
