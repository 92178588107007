import * as React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Typography from '@mui/material/Typography';

import defaults from './defaults';

const PageTitle = withSubtheme(props => {
    const {
        className,
        children,
        pageTitleProps
    } = props

    return(
        <Typography classes={{root: className}} {...pageTitleProps}>
            {children}
        </Typography>
    )
}, 'globalPageTitle', defaults)

export default PageTitle;
